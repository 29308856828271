import {
  GET_PAYMENT_DATA,
  GET_PAYMENT_DATA_ERROR,
  GET_PAYMENT_DATA_SUCCESS,
} from '../actionTypes/payment-data-constant';
import axiosInstance from '../config/axiosConfig';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

export const getPaymentData = (paymentLinkId, coreApiServer) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PAYMENT_DATA });
      axiosInstance.defaults.headers['origin-full'] = coreApiServer;
      const { data: {clubspeedData} } = await axiosInstance({
        method: 'get',
        url: `payments/link?paymentLinkId=${paymentLinkId}`,
        headers: {}
      });
      dispatch({ type: GET_PAYMENT_DATA_SUCCESS, payload: clubspeedData });
      return dispatch(getPaymentCheckData(clubspeedData.checkId, coreApiServer));
    } catch (error) {
      return dispatch({ type: GET_PAYMENT_DATA_ERROR, payload: false });
    }
  };
};

export const getPaymentCheckData = (checkId, coreApiServer) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PAYMENT_DATA });
      axiosInstance.defaults.headers['origin-full'] = coreApiServer;
      const { data: { clubspeedData: { checkRemainingTotal } } } = await axiosInstance({
        method: 'get',
        url: `payments/check?checkId=${checkId}`,
        headers: {}
      });
      return dispatch({ type: GET_PAYMENT_DATA_SUCCESS, payload: { checkRemainingTotal } });
    } catch (e) {
      return dispatch({ type: GET_PAYMENT_DATA_ERROR, payload: false });
    }
  };
};
