import axiosInstance from '../axiosConfig';

const addHeaderDataInterceptor = async (requestConfig) => {
  if(!requestConfig.headers.common['Authorization'] && requestConfig.url !== 'auth') {
    const { data } = await axiosInstance('auth');
    if(data && data.token){
      requestConfig.headers.common['Authorization'] = `Bearer ${data.token}`;
    }
  }
  return requestConfig;
};

export default addHeaderDataInterceptor;
