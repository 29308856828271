import React from "react";
import "./form.css";
import { StripeProvider, Elements } from "react-stripe-elements";
import CheckoutForm from "./CheckoutForm";


const PaymentFormStripe = () => {
  return (
      <>

<div className="heading_content">
                    <h1 className="heading">ALMOST THERE!</h1>
                    <p className="sub_heading">

                    Stand out from the bunch and give the gift of adrenaline this holiday
                    season!
                    </p>
                </div>
      <div className="form_container">
        <div className="checkout-component">
          <StripeProvider apiKey={process.env.REACT_APP_STRIP_KEY}>
            <Elements>
              <CheckoutForm />
            </Elements>
          </StripeProvider>
        </div>
      </div>
      </>
  );
};

export default PaymentFormStripe;
