import axios from 'axios';
import addHeaderDataInterceptor from './interceptors/addHeaderDataInterceptor';
import refreshTokenInterceptor from './interceptors/refreshTokenInterceptor';
import updateTokenInterceptor from './interceptors/updateTokenInterceptor';
const { REACT_APP_CORE_API } = process.env;

const instance = axios.create({
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
  withCredentials: true
});

if (REACT_APP_CORE_API) {
  instance.defaults.baseURL = REACT_APP_CORE_API;
}

instance.interceptors.request.use(addHeaderDataInterceptor, Promise.reject);

// instance.interceptors.response.use(
//   (response) => updateTokenInterceptor(response, instance),
//   refreshTokenInterceptor(instance)
// );

export default instance;
