import React from 'react';
import ReactModal from 'react-modal';

ReactModal.setAppElement('#root');

const Alert = ({ isOpen, closeButtonHandler, children }) => {
  return (
    <ReactModal isOpen={isOpen} className='modal' overlayClassName='modal-overlay'>
      <div className='modal-container'>
        {children}
        <button onClick={closeButtonHandler} className='modal-btn'>OK</button>
      </div>
    </ReactModal>
  );
};

export default Alert;
