import {
  MAKE_CARD_CONNECT_PAYMENT,
  MAKE_CARD_CONNECT_PAYMENT_SUCCESS,
  MAKE_CARD_CONNECT_PAYMENT_ERROR,
  RESET_ERROR_PAYMENT_MESSAGE,
} from '../actionTypes/make-payment-constant';

const INITIAL_STATE = {
  isLoading: false,
  errorMessage: null,
  isSessionSuccess: false,
};

const makePaymentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MAKE_CARD_CONNECT_PAYMENT:
    {
      return {
        ...state,
        isLoading: true,
      };
    }
    case MAKE_CARD_CONNECT_PAYMENT_SUCCESS:
    {
      return {
        ...state,
        isSessionSuccess: true,
        isLoading: false,
      };
    }
    case MAKE_CARD_CONNECT_PAYMENT_ERROR:
    {
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        isLoading: false,
      };
    }
    case RESET_ERROR_PAYMENT_MESSAGE:
    {
      return {
        ...state,
        errorMessage: null,
      };
    }
    default:
      return state;

  }
};

export default makePaymentReducer;
