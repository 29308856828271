import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import MakePayment from './components/Payment';
import './App.css';

const App = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/:linkData" component={MakePayment} />
    </Switch>
  </BrowserRouter>
);

export default App;
