import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  CardElement,
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  injectStripe,
} from "react-stripe-elements";
// import { makePayment } from "../../action/make-payment-action";

const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize,
        color: "#424770",
        background: 'red',
        letterSpacing: "0.025em",
        fontFamily: "Source Code Pro, monospace",
        "::placeholder": {
          color: "#aab7c4",
        },
        padding,
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };
};



const CheckoutForm = (props) => {
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const dispatch = useDispatch();
  let btnRef = useRef();

  const handleChange = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handlePay = async (ev) => {
    ev.preventDefault();

    setProcessing(true);

    const token = await props.stripe.createToken();

    props.stripe.createSource({
      type: "card",
      owner: {
        name: "Jenny Rosen",
      },
    });
    // dispatch(makePayment(token));
  };

  return (
      <form onSubmit={handlePay}>
        <label>
          Card number
          <CardNumberElement
            onChange={handleChange}
            {...createOptions(props?.fontSize)}
          />
        </label>
        <label className="customInput">
          Expiration date
          <CardExpiryElement
            onChange={handleChange}
            {...createOptions(props?.fontSize)}
          />
        </label>
        <label className="customInput">
          CVC
          <div className="wrapper">
          <CardCVCElement
            onChange={handleChange}
            {...createOptions(props?.fontSize)}
          />
          <span>Last 3 digits on back of card</span>
          </div>

        </label>
        <div className="paymentTotal">
          <h5>Total</h5>
          <span>$119.10</span>
        </div>
        <button className="btn-pay"> Pay </button>
      </form>
  );
};

export default injectStripe(CheckoutForm);
