import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import paymentDataReducer from './payment-data-reducer';
import makePaymentReducer from './make-payment-reducer';

const allReducers =  (history) => combineReducers({
  makePayment: makePaymentReducer,
  paymentData: paymentDataReducer,
  router: connectRouter(history)
});

export default (history) => (state, action) => {
  return allReducers(history)(state, action);
};
