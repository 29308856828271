import {
  MAKE_CARD_CONNECT_PAYMENT,
  MAKE_CARD_CONNECT_PAYMENT_SUCCESS,
  MAKE_CARD_CONNECT_PAYMENT_ERROR,
  RESET_ERROR_PAYMENT_MESSAGE
} from '../actionTypes/make-payment-constant';
import axiosInstance from '../config/axiosConfig';

export const makeCardConnectPayment = (token, paymentLinkId, checkId, cardExpirationDate, cvv2, postal) => {
  return async(dispatch) => {
    try {
      dispatch({ type: MAKE_CARD_CONNECT_PAYMENT });

      const { data: {clubspeedData} } = await axiosInstance({
        method: 'post',
        url: 'payments/pay/cc',
        data: {token, paymentLinkId, cardExpirationDate, checkId: checkId, kdsRequestItems: null, cvv: cvv2, postalCode: postal}
      });
      if(clubspeedData.errorMessage) {
        return dispatch({ type: MAKE_CARD_CONNECT_PAYMENT_ERROR, payload: clubspeedData });
      }
      return dispatch({ type: MAKE_CARD_CONNECT_PAYMENT_SUCCESS, payload: clubspeedData });
    } catch (error) {
      return dispatch({ type: MAKE_CARD_CONNECT_PAYMENT_ERROR, payload: false });
    }
  };
};

export const resetErrorPaymentMessage = () => {
  return async(dispatch) => {
    dispatch({ type: RESET_ERROR_PAYMENT_MESSAGE });
  };
};

export const makeStripePayment = () => {};
