import React from 'react';
import warningIcon from '../../assets/img/warning.svg';
import './expired-payment.css';

const ExpiredPayment = () => {
  return (
    <div className='expired-payment-wrapper'>
      <span className='img-wrap'>
        <img src={warningIcon} alt='img' />
      </span>
      <h4>Sorry!</h4>
      <p>This payment link has expired.</p>
      <p>Please call or see a member of our staff to complete your purchase or request a new payment link.</p>
    </div>
  );
};

export default ExpiredPayment;
