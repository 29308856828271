import React from "react";
import ticket from'../../assets/img/ticket.svg';
import './payment-success.css';

const SuccessfulPayment = ({title, children}) => {
  return (
    <div className="payment-success-wrapper">
      <span className="img-wrap">
        <img src={ticket} alt="img" />
      </span>
      <h4>{title}</h4>
      {children}
      {/*{*/}
      {/*  true && (*/}
      {/*    <>*/}
      {/*      <h4>Thank you!</h4>*/}
      {/*      <h6>We&apos;ve emailed you the receipt.</h6>*/}
      {/*    </>*/}
      {/*  )*/}
      {/*}*/}
      {/*{*/}
      {/*  true && (*/}
      {/*    <>*/}
      {/*      <h4>Good news!</h4>*/}
      {/*      <h6>You&apos;ve already paid for your purchase.</h6>*/}
      {/*      <h6>You&apos;re good to go!</h6>*/}
      {/*    </>*/}
      {/*  )*/}
      {/*}*/}
    </div>
  )
}

export default SuccessfulPayment
